import { Action, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import * as ScrapActions from './scrap.action';
import * as moment from 'moment';
export const ScrapFeatureKey = 'dank';

export interface ScrapState {
  readonly [ScrapFeatureKey]: any;
}

export const scrapInitialState: any = {
  error: '',
  user:'',
  //discordData:'',
  //discordError:false,
  login:'',
  nfts:[],
  play:'',
  reset:'',
  scoreboard:[]

}

const reducer = createReducer(
    scrapInitialState,
//UNIVERSAL
immerOn(ScrapActions.getUserSuccess, (state:any, action:any) => {
    console.log(action)
    state.user = action.user;
    state.error = '';
  }),

  immerOn(ScrapActions.getUserFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.user = '';
  }),

  immerOn(ScrapActions.loginSuccess, (state:any, action:any) => {
    state.login = action.wallet;
    state.error = '';
  }),

  immerOn(ScrapActions.loginFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.login = '';
  }),

  immerOn(ScrapActions.createUserSuccess, (state:any, action:any) => {
    state.user = action.user;
    state.error = '';
  }),

  immerOn(ScrapActions.createUserFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.user = '';
  }),
  immerOn(ScrapActions.createPlayFabSuccess, (state:any, action:any) => {
    state.play = action.play;
    state.error = '';
  }),

  immerOn(ScrapActions.createPlayFabFail, (state:any, action:any) => {
    console.log(action)
    state.error=action.error.error.errorMessage;
    state.play = '';
  }),

  immerOn(ScrapActions.resetPlayFabSuccess, (state:any, action:any) => {
    state.reset = action.reset;
    state.error = '';
  }),

  immerOn(ScrapActions.resetPlayFabFail, (state:any, action:any) => {
    console.log(action)
    state.error=action.error.error.errorMessage;
    state.reset = '';
  }),
  immerOn(ScrapActions.getNftsSuccess, (state:any, action:any) => {
    state.nfts = action.Nfts;
    state.error = '';
  }),

  immerOn(ScrapActions.getNftsFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.nfts = '';
  }),

  immerOn(ScrapActions.getScoreboardSuccess, (state:any, action:any) => {
    state.scoreboard = action.scoreboard;
    state.error = '';
  }),

  immerOn(ScrapActions.getScoreboardFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.scoreboard = [];
  })
)

export function scrapReducer(state: any, action: Action): any {
    return reducer(state, action);
}