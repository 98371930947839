import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { ApiService } from './api.service';
import { createClient } from 'urql';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class MainService {
  constructor(private apiService: ApiService) {}


  getUser(wallet: string){
    return this.apiService.post('/api/users/find',{wallet:wallet})
  }

  createUser(wallet:any, profilePic: any, username:any, email:any, password:any){
    return this.apiService.post('/api/users/new', {wallet:wallet, profilePic: profilePic, username:username, email:email, password:password});
  }

  createPlayFab(username:any, email:any, password:any){
    return this.apiService.postCust('https://7C045.playfabapi.com/Client/RegisterPlayFabUser','',{
      "TitleId":"7C045",
      "DisplayName":username,
      "Password":password,
      "Username":username,
      "Email":email
    });
  }

  sendResetLink(email:any){
    return this.apiService.postCust('https://7C045.playfabapi.com/Client/SendAccountRecoveryEmail','',{
      "TitleId":"7C045",
      "Email":email
    });
  }

  getPlayfabAuth(){
    //return this.apiService.post('/api/users/scores',{});
  }

  getScoreboard(){
    return this.apiService.post('/api/users/scores',{});
  }

}