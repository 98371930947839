import { Injectable  } from '@angular/core';
import { Component } from '@angular/core';
import Moralis from 'moralis';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class Web3Service {
  constructor() {
  }

  async initMoralis(){
    console.log('moralis')
    await Moralis.start({
      appId: environment.moralisAppId,
      serverUrl: environment.moralisUrl,
    });
    console.log('Moralis Initialized')
  }

  async login(provider: 'metamask' | 'walletconnect' = 'metamask'){
    try {
      console.log('Login')
      await this.initMoralis();
      if(localStorage.getItem('wallet') && Moralis.User.current()){
  
        return Moralis.User.current().attributes.accounts[0];
      }

      if(provider === 'metamask'){
        let login = await Moralis.authenticate();
        return Moralis.User.current().attributes.accounts[0];
      }
      else{
        let login = await Moralis.authenticate({ provider });
        return Moralis.User.current().attributes.accounts[0];
      }
    }
    catch(err){
      return {error:true,message:err.message};
    }
  }

  async logout(){
    try {
      await Moralis.User.logOut();
      await Moralis.cleanup();
      return;
    }
    catch(err){
      return {error:true,message:err.message};
    }
  }

  async walletEnable(){
    var provider;
      let providers = await Moralis.connectorType;
      let current = await Moralis.User.current;


      if(!providers){
        if(localStorage.getItem('wallet') === 'metamask'){
          await this.login();
        }
        else {
          await this.login('walletconnect');
        }
      }
      let currentP = Moralis.provider;

      if(Moralis.isWeb3Enabled()){
        provider = Moralis.web3;
      }

      else if(providers === 'injected'){
        provider = await Moralis.enableWeb3();
  
      }
      else{
        provider = await Moralis.enableWeb3({provider:"walletconnect"});
      }

      return provider;
    
    
  }

  async checkNetwork(){
    try{
      let provider = await this.walletEnable() as any;
      let { chainId } = await provider.getNetwork()
      return chainId;
    }
    catch(error){
      return 0;
    }
    
  }

  async getNFTsWallet(){
    try{
      let ethers = Moralis.web3Library;
      let address = Moralis.account;
      const ethNFTs = await Moralis.Web3API.account.getNFTs({
        chain: "eth",
        address: address as string
      });

      let nfts=[];
      for(let x = 0; ethNFTs.result.length > x; x++){
        //let response = await this.fetchURI(ethNFTs.result[x].token_address, ethNFTs.result[x].token_id);
        let response = await fetch('https://api.opensea.io/api/v1/asset/'+ethNFTs.result[x].token_address +'/'+ethNFTs.result[x].token_id+'/?include_orders=false');
        let data;
        let img = '';
        if(response) {
          data = await response.json();
          if(data){
            img = data.image_url;
          }
        }
        
        nfts.push({img:img,name:ethNFTs.result[x].name,token_address:ethNFTs.result[x].token_address, token_id:ethNFTs.result[x].token_id, owner_of:ethNFTs.result[x].owner_of});
      }
      
      return nfts;

      //return ethNFTs.result;
    }
    catch(err){
      return {error:true,message:err.message};

    }
  }

}
