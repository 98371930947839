import { createAction, props } from '@ngrx/store';

export const login = createAction('[scrap] GET_LOGIN',
  props<{provider: any }>()
);

export const loginFail = createAction('[scrap] GET_LOGIN_FAIL',
  props<{error: any }>()
);

export const loginSuccess = createAction('[scrap] GET_LOGIN_SUCCESS',
  props<{wallet: any }>()
);

export const getLogOut = createAction('[scrap] GET_LOGOUT',
props<{data: any }>()
);

export const getLogOutSuccess = createAction('[scrap] GET_LOGOUT_SUCCESS',
props<{logout: any }>()
);

export const getLogOutFail = createAction('[scrap] GET_LOGOUT_FAIL',
props<{error: any }>()
);

export const getUser = createAction('[scrap] GET_USER',
  props<{address: any }>()
);

export const getUserFail = createAction('[scrap] GET_USER_FAIL',
  props<{error: any }>()
);

export const getUserSuccess = createAction('[scrap] GET_USER_SUCCESS',
  props<{user: any }>()
);

export const createUser = createAction('[scrap] CREATE_USER',
  props<{wallet:any, profilePic: any, username:any, email:any, password:any }>()
);

export const createUserFail = createAction('[scrap] CREATE_USER_FAIL',
  props<{error: any }>()
);

export const createUserSuccess = createAction('[scrap] CREATE_USER_SUCCESS',
  props<{user: any }>()
);

export const createPlayFab = createAction('[scrap] CREATE_PLAYFAB',
  props<{ username:any, email:any, password:any }>()
);

export const createPlayFabFail = createAction('[scrap] CREATE_PLAYFAB_FAIL',
  props<{error: any }>()
);

export const createPlayFabSuccess = createAction('[scrap] CREATE_PLAYFAB_SUCCESS',
  props<{play: any }>()
);

export const resetPlayFab = createAction('[scrap] RESET_PLAYFAB',
  props<{ email:any}>()
);

export const resetPlayFabFail = createAction('[scrap] RESET_PLAYFAB_FAIL',
  props<{error: any }>()
);

export const resetPlayFabSuccess = createAction('[scrap] RESET_PLAYFAB_SUCCESS',
  props<{reset: any }>()
);

export const getScoreboard = createAction('[scrap] SCOREBOARD',
  props<{ data:any}>()
);

export const getScoreboardFail = createAction('[scrap] SCOREBOARD_FAIL',
  props<{error: any }>()
);

export const getScoreboardSuccess = createAction('[scrap] SCOREBOARD_SUCCESS',
  props<{scoreboard: any }>()
);

export const playFabAuth = createAction('[scrap] PLAYFAB_AUTH',
  props<{ data:any}>()
);

export const playFabAuthFail = createAction('[scrap] PLAYFAB_AUTH_FAIL',
  props<{error: any }>()
);

export const playFabAuthSuccess = createAction('[scrap] PLAYFAB_AUTH_SUCCESS',
  props<{auth: any }>()
);

export const getNfts = createAction(
    '[scrap] GET_NFTS',
    props<{ data:any }>()
    );
  
  export const getNftsSuccess = createAction(
      '[scrap] GET_NFTS_SUCCESS',
      props<{ Nfts: any}>()
    );
  
  export const getNftsFail = createAction(
      '[scrap] GET_NFTS_FAIL',
      props<{ error: any }>()
  );