import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavFacade } from 'src/app/lib/facades/nav.facade';


@Component({
  selector: 'app-leader-board',
  templateUrl: './leader-board.component.html',
  styleUrls: ['./leader-board.component.scss']
})
export class LeaderBoardComponent implements OnInit {
  public scoreboard$: Observable<any>|undefined;
  constructor(private navFacade:NavFacade) { 
    
  }

  ngOnInit(): void {
    this.setObservables();
  }

  setObservables() {
    this.scoreboard$ = this.navFacade.scoreboard$;
    this.navFacade.getScoreboard();
  }

  getScoreboard(){

  }

}
