import { Component, OnInit, TemplateRef, Inject, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '../lib/services/api.service';
import { User } from '../model/user.model';
import Moralis from 'moralis';
import { environment } from '../../environments/environment.prod';
import { Nft } from '../model/nft.model';

import { Balances } from '../model/balance.model';
import { NavigationEnd, Router } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { Observable, Subject } from 'rxjs';
import { NavFacade } from '../lib/facades/nav.facade';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})


export class NavComponent implements OnInit, OnDestroy {
  
  public login$: Observable<any>|undefined;
  public error$: Observable<any>|undefined;
  public user$: Observable<any>|undefined;
  public nfts$: Observable<any>|undefined;
  public play$: Observable<any>|undefined;
  public reset$: Observable<any>|undefined;
  private destroy$ = new Subject();
  email:string = '';
  WalletForm: FormGroup;
  isCollapsed = true;
  user: any;
  wallet:string;
  walletFull:string;
  showReset:boolean = false;
  showWalletLogin:boolean = false;
  avatarSelect:any ='';
  community:boolean=false;
  more:boolean=false;
  sectionScroll:any;
  constructor(@Inject(DOCUMENT) private doc: Document, 
  private router: Router, private alert: AlertModule, private navFacade:NavFacade,) { 
    //this.auth = auths;
    document.addEventListener("mousedown", (ele):void => {      

      if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('community') < 0 && (ele.target as Element).className.indexOf('community') < 0){
        this.community = false;
      }
      if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('more') < 0 && (ele.target as Element).className.indexOf('more') < 0){
        this.more = false;
      }
      if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('navmob') < 0 && (ele.target as Element).className.indexOf('navmob') < 0){
        this.isCollapsed = true;
      }

    })
    
  }

  ngOnInit(): void {
    this.setObservables();
    /*if(!localStorage.getItem('wallet')) return;
    if(localStorage.getItem('wallet') === 'metamask'){
      this.navFacade.login('metamask')
    }
    else {
      this.navFacade.login('walletconnect')
    }*/
    this.initFormControl();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.doScroll();
      this.sectionScroll= null;
    });
   
  }

  internalRoute(page, dst){
    this.sectionScroll=dst;
    this.router.navigate([page], {fragment: dst});
  }

  doScroll() {

    if (!this.sectionScroll) {
      return;
    }
    try {
      var elements = document.getElementById(this.sectionScroll);

      elements.scrollIntoView();
    }
    finally{
      this.sectionScroll = null;
    }
  } 

  setObservables() {
    this.login$ = this.navFacade.login$;
    this.error$ = this.navFacade.error$;
    this.nfts$ = this.navFacade.nfts$
    this.user$ = this.navFacade.user$;
    this.play$ = this.navFacade.play$;
    this.reset$ = this.navFacade.reset$;
    this.play$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        console.log(message)
        //this.user = message;
        //this.wallet = this.user.Wallet.slice(0, 4) + "..." + this.user.Wallet.slice(41, 44);
        //route to client
      }

    });

    this.login$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      console.log(message);
      if(message){
        console.log(message)
        this.walletFull = message;
        
      }

    });
    //this.navFacade.getPlayFab();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  controlModal(desc:string){
    this.showWalletLogin = true;
  }

  clear(){
    this.showWalletLogin = false;
    this.showReset = false;
  }

  login(provider: 'metamask' | 'walletconnect' = 'metamask') {
    console.log(provider)
    localStorage.setItem('wallet', provider);
    this.navFacade.login(provider);
  }

  logout() {
    
  }

  initFormControl(){
    this.WalletForm = new FormGroup({
      'Username' : new FormControl(null, [Validators.required]),
      'Email' : new FormControl(null, [Validators.required]),
      'Password' : new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(100)]),
      'PasswordValidate' : new FormControl(null, [Validators.required]),
    });
  }


  submitUser(){
    if(this.WalletForm.invalid){
      return;
    }
    this.navFacade.createPlayFab(this.WalletForm.controls.Username.value,this.WalletForm.controls.Email.value,this.WalletForm.controls.Password.value);
    //this.navFacade.createUser(this.walletFull, this.avatarSelect.url, this.WalletForm.controls.Username.value,this.WalletForm.controls.Email.value,this.WalletForm.controls.Password.value );
    
  }

  isHomeRoute() {
    if(this.router.url.indexOf('/game')> -1){
      var element = document.getElementById("navbar-top");
      element.classList.remove("navbar-transparent");
      element.classList.add("bg-[#000] bg-opacity-10");
    }
    return this.router.url.indexOf('/home') >= 0;
  }

  selectAvatar(id:string, contract:string, nft:any){
    //if select then deselect
    console.log(nft.img)
    this.avatarSelect = {
      token_id:id, 
      token_address:contract,
      url:nft.img
    }
   
  }

  isAvatarSelected(id:string, contract:string){
    return this.avatarSelect.token_address === contract && this.avatarSelect.token_id === id;
  }
  
  showResetModal(){
    this.clear();
    this.showReset= true;
  }

  resetAccount(){
    console.log(this.email)
    this.navFacade.resetPlayFab(this.email);
    this.showReset = false;
    this.showWalletLogin = false;
  }

}
