import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  host:{
    '(document:mousemove)':'animateBg($event)'
  }
})
export class FaqComponent implements OnInit {
  isCollapsed:boolean = false;
  isCollapsed1:boolean = false;
  isCollapsed2:boolean = false;
  isCollapsed3:boolean = false;
  isCollapsed4:boolean = false;
  isCollapsed5:boolean = false;
  isCollapsed6:boolean = false;
  isCollapsed7:boolean = false;
  isCollapsed8:boolean = false;
  isCollapsed9:boolean = false;
  isCollapsed10:boolean = false;
  isCollapsed11:boolean = false;
  isCollapsed12:boolean = false;
  isCollapsed13:boolean = false;
  isCollapsed14:boolean = false;
  isCollapsed15:boolean = false;
  isCollapsed16:boolean = false;
  isCollapsed17:boolean = false;
  isCollapsed18:boolean = false;
  isCollapsed19:boolean = false;
  isCollapsed20:boolean = false;
  isCollapsed21:boolean = false;
  isCollapsed22:boolean = false;
  isCollapsed23:boolean = false;
  isCollapsed24:boolean = false;
  isCollapsed25:boolean = false;
  isCollapsed26:boolean = false;
  isCollapsed27:boolean = false;
  isCollapsed28:boolean = false;
  isCollapsed29:boolean = false;
  isCollapsed30:boolean = false;
  constructor(@Inject(DOCUMENT) document) { }

  ngOnInit(): void {
  }

  animateBg(e){
    /*console.log(e.target.className);
    if(e.target.className === "subsec"){
      let elements = document.createElement('div');
    let att = document.createAttribute("style");
    att.value = 'position:fixed;overflow:hidden;width:100px;height:100px;border-radius:50%;background: #000 url(../../../assets/img/textBg.jpg);background-size:cover;background-attachment:fixed; z-index: 20;';
    elements.setAttributeNode(att);
    
    document.getElementById('faq1').append(elements);
    elements.style.left = e.clientX+'px';
    elements.style.top = e.clientY+'px';
    
    setTimeout(function(){
      elements.remove();
    }, 2000);
    }*/
    
  }

}
