import { Component, OnDestroy, OnInit } from '@angular/core';
import Chart from "chart.js";
@Component({
  selector: 'app-nft',
  templateUrl: './nft.component.html',
  styleUrls: ['./nft.component.scss']
})
export class NftComponent implements OnInit, OnDestroy {

  isCollapsed = true;
  constructor() {}

  ngOnInit() {
    
  }
  ngOnDestroy() {
   
  }

}
