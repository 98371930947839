import { Component, OnInit, TemplateRef,HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import * as THREE from 'three';
import { ApiService } from 'src/app/lib/services/api.service';

import { DOCUMENT } from '@angular/common'; 
import { transition } from '@angular/animations';
import { SwiperComponent } from "swiper/angular";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper";


// install Swiper modules
SwiperCore.use([Pagination]);
declare var myExtObject: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  host:{
    '(document:scroll)':'scroll($event)',
    '(document:mousemove)':'mouseMove($event)'

  }
})
export class MainComponent implements OnInit {
  @ViewChild('team') public structure : ElementRef;

  @ViewChild('time') public info : ElementRef;
  displayCar:boolean;
  stars: any;
  prevScrollY:any;
  full:boolean;
  targetY :any;
  set: any;
  translateTeam:number = 0;
  ability:number = 1;
  topOff:number = 0;
  abilityDir:boolean = true;
  switch:number = 0;
  
  projectList:any[] = [
    {
      name1:"Neo Tokyo Citizen",
      name2:"Neo Tokyo Outer Citizen",
      desc1:"Hack",
      desc2:"Cloak",
      select:true,
      img1:"assets/img/nt.png",
      img2:"assets/img/nt.png",

    },
    {
      name1:"BAYC",
      name2:"MAYC",
      desc1:"Boredom",
      desc2:"Mutate",
      select:true,
      img1:"https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s168",
      img2:"https://lh3.googleusercontent.com/lHexKRMpw-aoSyB1WdFBff5yfANLReFxHzt1DOj_sg7mS14yARpuvYcUtsyyx-Nkpk6WTcUPFoG53VnLJezYi8hAs0OxNZwlw6Y-dmI=s168",

    },
    {
      name1:"Cyberkongz",
      name2:"Cyberkongz (Babies)",
      desc1:"Ape Strong",
      desc2:"Sling Poo",
      select:true,
      img1:"https://lh3.googleusercontent.com/LIpf9z6Ux8uxn69auBME9FCTXpXqSYFo8ZLO1GaM8T7S3hiKScHaClXe0ZdhTv5br6FE2g5i-J5SobhKFsYfe6CIMCv-UfnrlYFWOM4=s168",
      img2:"https://lh3.googleusercontent.com/iGVQ_0I1nj4paQ1GJemfCwNqTm64XRMU3CO7y0bi-C-im7edte2YWf1hgNuShpRXC70xBoUVGFQCjTsyXkMD2tPX17VOJBn1wjQZ=s168",

    },
    {
      name1:"Doge Pound",
      name2:"Doge Pound Puppies",
      desc1:"Howl",
      desc2:"Lick Wounds",
      select:true,
      img1:"https://lh3.googleusercontent.com/ElvjC_ZgTNta6T0cHuqXCJleTBU17in2yriPnojIHcOjXjyGGZ_R4299LUT8h_cYQ5KZCz0yUa3gL5HTQStFdb-BXA5R-N-rVTJUCEA=s168",
      img2:"https://lh3.googleusercontent.com/41WE5Jszkq_l97zeIPdvBZb0l_558xGQnI04kVqQib5bgSOJkrtGlfcWiojfI5VFTBpfg0-u2bhojgP9FKMIcQ4IPf8Ky5wsr2pI=s168",

    }
  ]
  
  constructor( @Inject(DOCUMENT) document){

    
  }

  scroll(event){
    let value = window.scrollY;
    this.reveal();
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let ele = document.getElementById('sec');
      let ele2 = document.getElementById('sec2');
      let text = document.getElementById('text1');
      this.topOff= document.getElementById('par').offsetTop;
      let bantopOff= document.getElementById('ban').offsetTop;
      let teamtopOff= document.getElementById('team').offsetTop;
      if(this.topOff <= (scrollTop) && teamtopOff > scrollTop ){
        // ele.classList.remove('-top-[6rem]')
         
         text.classList.add('fixed')
         text.classList.remove('absolute')
         //ele.classList.add('-top-[8rem]')
       }
       else{
       
         text.classList.add('absolute')
         text.classList.remove('fixed')
       }
      if(this.topOff <= (scrollTop) && teamtopOff > scrollTop ){
       // ele.classList.remove('-top-[6rem]')
        ele.classList.remove('absolute')
        ele.classList.add('fixed')
        ele2.classList.add('hidden')
      }
      else{
        //ele.classList.add('-top-[6rem]')
        //ele.classList.remove('-top-[8rem]')
        ele.classList.remove('fixed')
        ele.classList.add('absolute')
        ele2.classList.remove('hidden')
       
      }

      if(bantopOff + 300 <= scrollTop){
        this.switch = 1;
      }
      else{
        this.switch = 0;
      }
      this.scrollHandler(document.querySelectorAll('.tsection'), document.querySelector('.timeline'), document.querySelector('.line'));


  }

  reveal(){
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("opacity-100");
        reveals[i].classList.remove("opacity-0");
      } else {
        reveals[i].classList.add("opacity-0");
        reveals[i].classList.remove("opacity-100");
      }
    }
  }
  
      

  mouseMove(e){
    var valueX = e.pageX / window.innerHeight;

      var valueY = e.pageY / window.innerWidth;

     // document.getElementById('bg').style.webkitTransform = 'translateX(' + valueX * -.5 + '%)'+ ' translateY(' + valueY * -.5 + '%)';
     // document.getElementById('bg').style.transform = 'translateX(' + valueX * -.5 + '%)'+ ' translateY(' + valueY * -.5 + '%)';
  
     // document.getElementById('buildings').style.webkitTransform = 'translateX(' + valueX * -1 + '%)' + ' translateY(' + valueY * -1 + '%)';
     // document.getElementById('buildings').style.transform = 'translateX(' + valueX * -1 + '%)' + ' translateY(' + valueY * -1 + '%)';

      document.getElementById('attack').style.webkitTransform = 'translateX(' + valueX * -1 + '%)' + ' translateY(' + valueY * -1 + '%)';
      document.getElementById('attack').style.transform = 'translateX(' + valueX * -1 + '%)' + ' translateY(' + valueY * -1 + '%)';
      
      document.getElementById('front_car').style.webkitTransform = 'translateX(' + valueX * 1 + '%)' + ' translateY(0%)';
      document.getElementById('front_car').style.transform = 'translateX(' + valueX * 1 + '%)' + ' translateY(0%)';
  }

  ngOnInit() { 

    this.topOff= document.getElementById('par').offsetTop;
    console.log(this.topOff)
    var line = this.qs('.line');
    line.style.display = 'block';
    this.targetY = window.innerHeight * .8;
    this.full = false;
    this.set = 0;
    this.scrollHandler(document.querySelectorAll('.tsection'), document.querySelector('.timeline'), document.querySelector('.line'));
    this.displayCar = false;
    this.startRotate(6);
  }

  qs(selector, all = false) {
    return all ? document.querySelectorAll(selector) : document.querySelector(selector);
  }

  scrollHandler(sections, timeline, line) {
    const {
      scrollY
    } = window;
    var up = scrollY < this.prevScrollY;
    var down = !up;
    const timelineRect = timeline.getBoundingClientRect();
    const lineRect = line.getBoundingClientRect(); // const lineHeight = lineRect.bottom - lineRect.top;
    
    const dist = this.targetY - timelineRect.top;
    
    if (down && !this.full) {
      this.set = Math.max(this.set, dist);
      line.style.bottom = `calc(100% - ${this.set}px)`;
    }
  
    if (dist > timeline.offsetHeight + 50 && !this.full) {
      this.full = true;
      line.style.bottom = `-50px`;
    }

    sections.forEach(item => {
      // console.log(item);
      const rect = item.getBoundingClientRect(); //     console.log(rect);
  
      if (rect.top + item.offsetHeight / 5 < this.targetY) {
        item.classList.add('show-me');
      }
    }); // console.log(up, down);
  
    this.prevScrollY = window.scrollY;
  }

  showCar(){
    this.displayCar = true;
  }

  register(): void {
    //this.auth.loginWithRedirect({ screen_hint: 'signup' });
    //this.service.register();
  }

  moveTeam(direction){
    let cards = Math.floor((window.screen.width / 330)) *330;
    let beyond = (330* 11);

    if(direction === 'left'){
      if(this.translateTeam <=0){
        return;
      }
      this.translateTeam -= cards
    }
    else if(direction === 'right'){
      if((this.translateTeam + cards) >= beyond){
        return;
      }
      this.translateTeam += cards
    }
  }

  async startRotate(seconds:number){
    let mili = seconds * 1000;
    if(mili > 0){
      await this.delay(mili);

      this.changeCollab();
    }
    
  }

  delay(t) {
    return new Promise(resolve => setTimeout(resolve, t))
  }


  changeCollab(){
    if(this.ability + 1 > (this.projectList.length-1)){
      this.abilityDir = false;
    }

    if(this.ability - 1 < 0){
      this.abilityDir = true;
    }
    if(this.abilityDir){
      this.ability += 1;
    }
    else{
      this.ability -= 1;
    }

    this.startRotate(6);
  }

  changeProject(){}


}
