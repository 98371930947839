import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScrapFeatureKey } from './scrap.reducer';

const getScrapFeatureKey = createFeatureSelector<any>(ScrapFeatureKey);

export const getError = createSelector(
    getScrapFeatureKey,
(state: any) => {
  return state.error
}
);

export const getUser = createSelector(
    getScrapFeatureKey,
(state: any) => {
  return state.user
}
);

export const getLogin = createSelector(
    getScrapFeatureKey,
(state: any) => {
  return state.login
}
);

export const getNfts = createSelector(
    getScrapFeatureKey,
(state: any) => {
  return state.nfts
}
);

export const getPlayfab = createSelector(
  getScrapFeatureKey,
(state: any) => {
return state.play
}
);

export const getResetPlayfab = createSelector(
  getScrapFeatureKey,
(state: any) => {
return state.reset
}
);

export const getScoreboard = createSelector(
  getScrapFeatureKey,
(state: any) => {
return state.scoreboard
}
);

export const ScrapQuery = {
    getScrapFeatureKey,
    getError,
    getLogin,
    getNfts,
    getUser,
    getPlayfab,
    getResetPlayfab,
    getScoreboard
};