import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import {ScrapQuery} from '../+state/scrap.selector';
import * as ScrapActions from '../+state/scrap.action';
import {ScrapState} from '../+state/scrap.reducer';

@Injectable({ providedIn: 'root' })
export class NavFacade {
    error$ = this.store.select(ScrapQuery.getError);
    user$ = this.store.select(ScrapQuery.getUser);
    login$ = this.store.select(ScrapQuery.getLogin);
    nfts$ = this.store.select(ScrapQuery.getNfts);
    play$ = this.store.select(ScrapQuery.getPlayfab);
    reset$ = this.store.select(ScrapQuery.getResetPlayfab);
    scoreboard$ = this.store.select(ScrapQuery.getScoreboard);
    constructor(private store: Store<ScrapState>) {}
    
    login(provider:string){
        this.store.dispatch(ScrapActions.login({provider:provider}));
    }

    getUser(address:string){
        this.store.dispatch(ScrapActions.getUser({address:address}))
    }

    createUser(profilePic:string, wallet:string, username:string, email:string, password:string){
        this.store.dispatch(ScrapActions.createUser({wallet:wallet, profilePic: profilePic, username:username, email:email, password:password}))
    }

    createPlayFab(username:string, email:string, password:string){
        this.store.dispatch(ScrapActions.createPlayFab({username:username, email:email, password:password}))
    }

    resetPlayFab(email:string){
        this.store.dispatch(ScrapActions.resetPlayFab({email:email}))
    }

    getScoreboard(){
        this.store.dispatch(ScrapActions.getScoreboard({data:null}))
    }
    getPlayFab(){
        this.store.dispatch(ScrapActions.playFabAuth({data:null}))
    }
}