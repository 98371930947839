
export const environment = {
  production: true,
  moralisAppId:"i7TR5jiJodMuaWaEbRnrS5OBWtDjyehXBYzPIU7n",
  moralisUrl:"https://hmipxjs2oqx8.usemoralis.com:2053/server",
  endpoint:"http://localhost:5000",
  token:"jdjaosiij28892SIAHI049923701CIGknlklajal",
  scrapContract:"0xcBA51e56cfdc1214077c6406295Fa2841aB8aaA6",
  nftContract:"0x6cE64590A47b7BB13bD6c28B91B244CD9c81CC11",
  siteKey:"6LeWI7IeAAAAAFKUA8VrkIOuhV-8Qc0A2DhlymRf"
};
