import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import Moralis from 'moralis';
import * as moment from 'moment';



@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent implements OnInit {
  user:any;
  wallet:any;
  items:any[];
  tokens:any[];
  balance: any[];

  scrapTotal: Number;
  constructor( ) {
    
   }

  ngOnInit(): void {

  }
 

  

  

}
