import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as ScrapActions from './scrap.action';
import { Action } from 'rxjs/internal/scheduler/Action';
import { ScrapState } from './scrap.reducer';
import { MainService } from '../services/main.service';
import { Web3Service } from '../services/web3.service';


@Injectable()
export class ScrapEffects {

   constructor(
    private actions$: Actions,
    private store: Store<ScrapState>,
    private mainService: MainService,
    private web3:Web3Service,
    
  ) {}

  login = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScrapActions.login),
      concatMap((action: any) => from(this.web3.login(action.provider)).pipe(
        map((data: any) =>{
            if(data.error){
                return ScrapActions.loginFail({error:{message:"ERROR OCCURED ON WALLET CONNECT."}});
            }
            this.store.dispatch(ScrapActions.getNfts({data:null}));
            //this.store.dispatch(ScrapActions.getUser({address:data}));
            return ScrapActions.loginSuccess({wallet: data});
        }),
        catchError((error) => {
          return of(ScrapActions.loginFail({error}));
        })

        )
      )
    );
    }
  );

  createUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScrapActions.createUser),
      concatMap((action: any) => this.mainService.createUser(action.wallet, action.profilePic, action.username, action.email, action.password).pipe(
        map((data: any) =>{
            ;
            return ScrapActions.createUserSuccess({user: data});
        }),
        catchError((error) => {
          return of(ScrapActions.createUserFail({error}));
        })

        )
      )
    );
    }
  );

  createPlayFab = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScrapActions.createPlayFab),
      concatMap((action: any) => this.mainService.createPlayFab(action.username, action.email, action.password).pipe(
        map((data: any) =>{
            ;
            return ScrapActions.createPlayFabSuccess({play: data});
        }),
        catchError((error) => {
          return of(ScrapActions.createPlayFabFail({error}));
        })

        )
      )
    );
    }
  );

  resetPlayFab = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScrapActions.resetPlayFab),
      concatMap((action: any) => this.mainService.sendResetLink(action.email).pipe(
        map((data: any) =>{
            
            return ScrapActions.resetPlayFabSuccess({reset: data});
        }),
        catchError((error) => {
          return of(ScrapActions.createPlayFabFail({error}));
        })

        )
      )
    );
    }
  );

  getNFTs = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScrapActions.getNfts),
      concatMap((action: any) => from(this.web3.getNFTsWallet()).pipe(
        map((data: any) =>{
            if(data.error){
                return ScrapActions.getNftsFail({error:{message:"ERROR OCCURED ON WALLET CONNECT."}});
            }
            
            return ScrapActions.getNftsSuccess({Nfts: data});
        }),
        catchError((error) => {
          return of(ScrapActions.getNftsFail({error}));
        })

        )
      )
    );
    }
  );

  getUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScrapActions.getUser),
      concatMap((action: any) => this.mainService.getUser(action.wallet).pipe(
        map((data: any) =>{
            
            return ScrapActions.getUserSuccess({user: data});
        }),
        catchError((error) => {
          return of(ScrapActions.getUserFail({error}));
        })

        )
      )
    );
    }
  );

 /*playFabAuth = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScrapActions.playFabAuth),
      concatMap((action: any) => this.mainService.getPlayfabAuth().pipe(
        map((data: any) =>{
          console.log(data)
          //this.store.dispatch(ScrapActions.getScoreboard({data:data}));
            return ScrapActions.playFabAuthSuccess({auth: data});
        }),
        catchError((error) => {
          console.log(error)
          return of(ScrapActions.playFabAuthFail({error}));
        })

        )
      )
    );
    }
  );*/

  scoreboard = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScrapActions.getScoreboard),
      concatMap((action: any) => this.mainService.getScoreboard().pipe(
        map((data: any) =>{
            console.log(data.scoreboard)
            return ScrapActions.getScoreboardSuccess({scoreboard: data.scoreboard});
        }),
        catchError((error) => {
          console.log(error)
          return of(ScrapActions.createPlayFabFail({error}));
        })

        )
      )
    );
    }
  );

}