// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  moralisAppId:"5Dr73hzwwH95BLKncv0km2nb6eefAIXcmWQN2HXm",
  moralisUrl:"https://dyihijucivgv.usemoralis.com:2053/server",
 // endpoint:"http://localhost:5000",
  endpoint:"https://avaapi.xyz",
  token:"jdjaosiij28892SIAHI049923701CIGknlklajal",
  scrapContract:"0xcBA51e56cfdc1214077c6406295Fa2841aB8aaA6",
  nftContract:"0x6cE64590A47b7BB13bD6c28B91B244CD9c81CC11",
  siteKey:"6LeWI7IeAAAAAFKUA8VrkIOuhV-8Qc0A2DhlymRf"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

